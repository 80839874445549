.parent {
    /* columns: 1px 6; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
}

@media (max-width: 1200px) {
    .parent {
        /* columns: 1px 4; */
        grid-template-columns: 1fr 1fr;
    }
}

.container {
    border: 1px solid #dadce0;
    overflow: auto;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.container a {
    margin-top: auto;
    overflow-wrap: break-word;
}

.child {
    width: 100%;
    margin-bottom: 10px;
}

/* where {column width} is usually fixed size
 * and {number of columns} is the maximum number of columns.
 * Additionally, to avoid breaks inside your elements, you want to add:
 */
.child {
    display: inline-block;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid-column;
    position: relative;
    margin-top: auto;
}

.child {
    border-radius: 5px;
    overflow: hidden;
}

.child:hover {
    box-shadow: 0 0 0 3px #3b84ff;
    cursor: pointer;
}

.loading {
    opacity: 0.5;
    transition: 0.2s ease;
}

.child img {
    display: block;
    object-fit: scale-down;
}

.child:not(.added):not(.loading):hover:after {
    content: 'add';
    font-family: 'Material Symbols Outlined', sans-serif;
    background-color: rgba(0, 87, 139, 0.5);
    font-size: 40px;
    color: #fff;
    text-shadow: 1px 1px 2px #000;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.over {
    position: absolute;
    top: 0;
    background-color: rgba(0, 87, 139, 0.5);
    opacity: 1;
    left: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.over i {
    font-size: 50px;
    color: #fff;
    text-shadow: 1px 1px 2px #000;
}

.over {
    font-size: 24px;
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 1px 2px #000;
}
