.root {
    background-image: url('https://static.trustoo.nl/misc/skeuomoprhism_switch.png');
    width: 1em;
    height: calc(3 / 4 * 1em);
    transform: rotate(90deg);
    font-size: 100px;
    background-size: calc(1em * 10);
    background-position-x: -2em;
    cursor: pointer;
    position: relative;
    margin: -4px auto;
}

.white {
    color: #fff;
}
.container {
    display: inline-block;
    text-align: center;
}
.wBg {
    background-color: #dddfdb;
    padding: 4px;
}
.label {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    display: inline-block;
    position: relative;
    z-index: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}
.wBorder


.label {
    border: 1px solid;
    padding: 2px 6px;
}
.root.on {
    animation: animOn 0.12s steps(3) forwards;
}
.root.off {
    animation: animOff 0.06s steps(2) forwards;

}
@keyframes animOn {
    100% { background-position-x: 1em; }
}
@keyframes animOff {
    100% { background-position-x: -4em; }
}
