.loading {
    opacity: 0.4;
    transition: opacity 0.2s ease;
    pointer-events: none;
}

.skeleton {
    height: 700px;
    width: 800px;
}
