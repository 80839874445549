.root {
    position: relative;
    transition: opacity 0.16s ease;
}

.loading {
    opacity: 0.4;
    pointer-events: none;
    cursor: wait;
}

.table {
    width: 100%;
}

.table th,
.table td {
    padding: 7px;
    text-align: left;
}

.header {
    text-transform: uppercase;
    font-size: 11px;
    border-top: 1px solid #cacaca;
}

.table th:last-child > div {
    width: 140px;
}

.table th:last-child :global(.DayPickerInput-Overlay) {
    font-weight: normal;
    text-transform: none;
}

.item {
    transition: background-color 0.16s ease;
    border-top: 1px solid #cacaca;
}

.item.future,
.item.future * {
    background-color: #c8ffc6;
    color: #0c6a09;
}

.item.past,
.item.past * {
    background-color: #ebebeb;
    color: #606060;
}

.item.deleted,
.item.deleted * {
    background-color: #666;
    color: #fff;
}

.item.deleted > td:not(.textButtonCol) {
    text-decoration: line-through;
}

.item.warning {
    background-image: repeating-linear-gradient(
            40deg,
            transparent,
            transparent 9px,
            #f70c0075 10px,
            #f70c0075 19px,
            transparent 20px
    );
}

.item.highlight,
.item.highlight * {
    background-color: #fffdbb;
    color: #6a6935;
    font-weight: bold;
    position: relative;
}

.item.highlight > td:first-child::before {
    content: 'fiber_new';
    font-family: 'Material Symbols Outlined', sans-serif;
    font-weight: normal;
    font-size: 26px;
    background-color: #fffdbb;
    color: #6a6935;
    border: 1px solid #6a6935;
    border-radius: 100%;
    position: absolute;
    left: -30px;
    top: calc(50% - 15px);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item.isNewFilter {
    background-color: #cbf0fc;
}

.amount {
    font-weight: bold;
}

.textButtonCol {
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
}

.textButtonCol button {
    font-weight: bold;
}

.saveButtonCol {
    text-align: center;
}

.addPriceButtonCol {
    flex-grow: 1;
    max-width: none;
    text-align: left;
}

.fullWidthButton,
.fullWidthSafeButton:first-child {
    width: 100%;
}

.fullWidthSafeButton:first-child ~ button {
    width: calc(50% - 5px);
    min-width: auto;
}

.bigHover {
    position: fixed !important;
    width: auto !important;
    height: auto !important;
    left: calc(50% - 300px) !important;
    right: calc(50% - 300px) !important;
    top: 16px !important;
    bottom: auto !important;
    z-index: 102 !important;
}

.ul {
    list-style-type: disc;
    padding-left: 15px;
}

.ul > li {
    padding-bottom: 10px;
}

.test {
    background-image: repeating-linear-gradient(
            40deg,
            #00000094,
            #00000094 9px,
            #f7d00075 10px,
            #f7d00075 19px,
            #00000094 20px
    );
    padding: 16px;
}

.test [class*='legend'] {
    background-color: #fdfdfdbf;
    padding: 4px;
    display: inline-block;
    margin-bottom: 8px;
}

.test button {
    background-color: #ca5e5e;
}

.test button:hover,
.test button:focus {
    background-color: #333;
}

.root input:disabled,
.root select:disabled {
    cursor: not-allowed;
}

.root input:disabled + div,
.root select:disabled + div,
.root select:disabled + div + div {
    opacity: 0.4;
}

.newIcon {
    margin-right: 5px;
}